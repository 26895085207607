
.panel-header-lg {
  transition: all .3s;
}

.chart {
  margin-top: 20px;
}

@media (min-width: 1890px) {
  .chart {
    margin-top: 0;
  }

  .panel-header-lg {
    height: 380px;
  }
}
