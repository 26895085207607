<template>
    <div>
        <div class="col-md-12" v-if="$root.$data?.dataHubState != 'Connected'">
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-stats card-raised">
                        <div class="card-body">
                            Dashboard Stats Disconnected. If this message lasts longer than 1 minute, please refresh the
                            page.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12" v-if="$root.$data?.dataHubState == 'Connected'">

            <!-- General Indicators -->
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-stats card-raised">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <div class="statistics">
                                        <div class="info" style="cursor:pointer" v-b-tooltip.hover
                                            title="Number of scripts entered today"
                                            @click="emitEvent('totalScriptsTodayClicked')">
                                            <div class="icon icon-primary">
                                                <i class="now-ui-icons files_single-copy-04" />
                                            </div>
                                            <h3 class="info-title">
                                                <animated-number :value="status.totalQuantities.scriptsTodayCount" />
                                            </h3>
                                            <h6 class="stats-title">
                                                Scripts Today
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="statistics">
                                        <div class="info" style="cursor:pointer" v-b-tooltip.hover
                                            title="Today's total revenue"
                                            @click="emitEvent('totalTodayRevenueClicked')">
                                            <div class="icon icon-success">
                                                <i class="now-ui-icons business_money-coins" />
                                            </div>
                                            <h3 class="info-title">
                                                <small>$</small>
                                                <animated-number
                                                    :value="status.shipmentSummaryReport.records.reduce((a, b) => a + b.totalRevenue, 0)" />
                                            </h3>
                                            <h6 class="stats-title">
                                                Revenue Booked Today
                                            </h6>
                                            <h6 class="stats-title">
                                                Unearned revenue:
                                            </h6>
                                            <h6>
                                                {{ status.getDispenseUnearnedTotal | currency }}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="statistics">
                                        <div class="info" style="cursor:pointer" v-b-tooltip.hover
                                            title="Number of orders currently being processed"
                                            @click="emitEvent('totalOpenOrdersClicked')">
                                            <div class="icon icon-info">
                                                <i class="now-ui-icons shopping_cart-simple" />
                                            </div>
                                            <h3 class="info-title">
                                                <animated-number :value="status.totalQuantities.openOrdersCount" />
                                            </h3>
                                            <h6 class="stats-title">
                                                Open Orders
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="statistics">
                                        <div class="info" style="cursor:pointer" v-b-tooltip.hover
                                            title="Number of orders shipped today"
                                            @click="emitEvent('totalOrdersShippedTodayClicked')">
                                            <div class="icon icon-danger">
                                                <i class="now-ui-icons shopping_delivery-fast" />
                                            </div>
                                            <h3 class="info-title">
                                                <animated-number :value="status.totalQuantities.shippedTodayCount" />
                                            </h3>
                                            <h6 class="stats-title">
                                                Orders Shipped Today
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <b-row>
                <!-- DISPENSE STATUS -->
                <b-col cols="12">
                    <DispenseStatusComponent v-show="isPartEnabled('dispensingStatusCard')"></DispenseStatusComponent>
                </b-col>
                <b-col cols="12" v-show="isPartEnabled('processingStatusCard')">
                    <!-- PROCESSING STATUS -->
                    <div class="card card-stats card-raised">
                        <div class="card-body">
                            <h3 class="mb-0">Processing Status</h3>
                            <b-row align-content="center" class="justify-content-md-center processingstatus">
                                <b-col v-show="isPartEnabled('insuranceQueueCount')">
                                    <CounterIndicator hide-hover-message
                                        :count='status.getProcessingStatusCount("insuranceQueueCount")'
                                        :warning-limit="getWarningLimit('InsuranceQueueWarningLimit')"
                                        route-name="PatientInsuranceQueue" name="Insurance Queue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('allergyQueueCount')">
                                    <CounterIndicator hide-hover-message
                                        :count='status.getProcessingStatusCount("allergyQueueCount")'
                                        :warning-limit="getWarningLimit('AllergyQueueWarningLimit')"
                                        route-name="PatientWebAllergyUpdateQueue" name="Allergy Queue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('onlineOrdersQueueCount')">
                                    <CounterIndicator :counts='status.getProcessingStatus("onlineOrdersQueueCount")'
                                        :warning-limit="getWarningLimit('OnlineOrdersWarningLimit')"
                                        name="Online Orders Queue" route-name="OnlineOrderQueue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('rxTransferQueueCount')">
                                    <CounterIndicator hide-hover-message
                                        :count='status.getProcessingStatusCount("rxTransferQueueCount")'
                                        :warning-limit="getWarningLimit('RxTransferQueueWarningLimit')"
                                        name="Rx Transfer Queue" route-name="RxTransferQueue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('openCasesQueueCount')">
                                    <CounterIndicator :counts='status.getProcessingStatus("openCasesQueueCount")'
                                        :warning-limit="getWarningLimit('OpenCasesQueueWarningLimit')"
                                        name="Open Cases Queue" route-name="CasesOpened" />
                                </b-col>
                                <b-col v-show="isPartEnabled('consultationsQueueCount')">
                                    <CounterIndicator :counts='status.getProcessingStatus("consultationsQueueCount")'
                                        :warning-limit="getWarningLimit('ConsultationsQueueWarningLimit')"
                                        route-name="Consultation" name="Consultations Queue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('pendingFollowUpQueueCount')">
                                    <CounterIndicator :counts='status.getProcessingStatus("pendingFollowUpQueueCount")'
                                        :warning-limit="getWarningLimit('PendingFollowUpQueueWarningLimit')"
                                        name="Dr. Follow-Up Queue" route-name="DoctorFollowUpQueue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('autoRefillQueueCount')">
                                    <CounterIndicator hide-hover-message
                                        :count='status.getProcessingStatusCount("autoRefillQueueCount")'
                                        :warning-limit="getWarningLimit('AutoRefillQueueWarningLimit')"
                                        route-name="AutorefillQueuePage" name="Auto Refill Queue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('consignmentQueueCount')">
                                    <CounterIndicator hide-hover-message
                                        :count='status.getProcessingStatusCount("consignmentQueueCount")'
                                        :warning-limit="getWarningLimit('ConsignmentQueueWarningLimit')"
                                        route-name="ConsignmentQueue" name="Consignment Queue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('pendingBillingQueueCount')">
                                    <CounterIndicator hide-hover-message
                                        :count='status.getProcessingStatusCount("pendingBillingQueueCount")'
                                        :warning-limit="getWarningLimit('PendingBillingQueueWarningLimit')"
                                        route-name="PendingForBillingQueuePage" name="Pending Billing Queue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('voicemails')">
                                    <CounterIndicator :hideHoverMessage='false' :countsVM='status.voiceMailDetail'
                                        :VMGroupCount='status.voicemailGroupCount'
                                        :warning-limit="getWarningLimit('VoicemailsWarningLimit')"
                                        route-name="VoicemailsNew" name="Voice Mail Queue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('partialPaymentQueueCount')">
                                    <CounterIndicator hide-hover-message
                                        :count='status.getProcessingStatusCount("partialPaymentQueueCount")'
                                        :warning-limit="getWarningLimit('PartialPaymentQueueWarningLimit')"
                                        route-name="PartialPaymentQueue" name="Partial Payment Queue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('reversalQueueCount')">
                                    <CounterIndicator hide-hover-message
                                        :count='status.getProcessingStatusCount("reversalQueueCount")'
                                        :warning-limit="getWarningLimit('ReversalQueueWarningLimit')"
                                        route-name="ReversalQueuePage" name="Reversal Queue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('returnedOrders')">
                                    <CounterIndicator hide-hover-message
                                        :count='status.getProcessingStatusCount("returnedOrders")'
                                        :warning-limit="getWarningLimit('ReturnedOrdersWarningLimit')"
                                        route-name="ReturnToSender" name="Returned Packages" />
                                </b-col>
                                <b-col v-show="isPartEnabled('patientAssistanceProgramQueueCount')">
                                    <CounterIndicator hide-hover-message
                                        :count="status.getProcessingStatusCount('patientAssistanceProgramQueueCount')"
                                        :warning-limit="getWarningLimit('patientAssistanceProgramQueueWarningLimit')"
                                        route-name="PatientAssistanceProgramQueue"
                                        name="Patient Assistance Program Queue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('priorAuthorizationQueueCount')">
                                    <CounterIndicator
                                        :counts='status.getProcessingStatus("priorAuthorizationQueueCount")'
                                        :warning-limit="getWarningLimit('priorAuthorizationQueueWarningLimit')"
                                        route-name="PriorAuthorizationList" name="Prior Authorizations" />
                                </b-col>
                                <b-col v-show="isPartEnabled('patientMergeRequestQueueCount')">
                                    <CounterIndicator hide-hover-message
                                        :count="status.getProcessingStatusCount('patientMergeRequestQueueCount')"
                                        :warning-limit="getWarningLimit('patientMergeRequestQueueWarningLimit')"
                                        route-name="PatientMergeQueue" name="Patient Merge Request Queue" />
                                </b-col>
                                <b-col v-show="isPartEnabled('expiredOnOrderQueueCount')">
                                    <CounterIndicator hide-hover-message
                                        :count="status.getProcessingStatusCount('expiredOnOrderQueueCount')"
                                        :warning-limit="getWarningLimit('expiredOnOrderQueueWarningLimit')"
                                        route-name="ExpiredOnOrderPage" name="Expired on Order Queue" />
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" v-show="isPartEnabled('workQueueCard')">
                    <!-- WORK QUEUES STATUS-->
                    <card>
                        <h3 class="mb-0">Work Queue Status</h3>
                        <BucketsTable :status="status.bucketStatus"></BucketsTable>
                        <hr>
                    </card>
                </b-col>

                <b-col cols="12" v-show="isPartEnabled('printQueueCard')">
                    <card>
                        <h3 class="mb-0">Print Queue Status</h3>
                        <PrintQueueTable :status="status.printQueueStatus" :sList="status.storeList">
                        </PrintQueueTable>
                    </card>
                </b-col>
                <b-col cols="12" v-show="isPartEnabled('salesStatusCard')">
                    <card>
                        <h3 class="mb-0">Sales Status</h3>
                        <SalesTable name='State' :status="status.salesRecordsByState"></SalesTable>
                        <hr>
                        <h3>Shipped Prescription Summary</h3>
                        <ShipmentSummaryReport v-model="status.shipmentSummaryReport"></ShipmentSummaryReport>
                    </card>
                </b-col>
            </b-row>
        </div>
        <b-row>

            <b-col class="ml-4">
                <span class="text-primary" @click="$bvModal.show('dashboardPreference')" role="button"><i
                        class="fa fa-gear mr-1"></i>Configure...</span>
            </b-col>
        </b-row>
        <b-modal id="dashboardPreference" title="Dashboard Preferences" hide-footer no-close-on-backdrop>
            Show Cards:
            <b-form-checkbox v-for="part in availableCards" :value="part[0]" :key="part[0]" v-model="enabledParts">{{
                part[1] }}</b-form-checkbox>
            Show Indicators:
            <b-form-checkbox v-for="part in availableIndicators" :value="part[0]" :key="part[0]"
                v-model="enabledParts">{{ part[1] }}</b-form-checkbox>
            <b-button variant="primary" @click="saveEnabledParts">Save</b-button>
            <b-button class="ml-1" @click="closeEnabledPartsModal">Cancel</b-button>
        </b-modal>
    </div>
</template>

<script lang='ts'>
import CounterIndicator from "@/components/Dashboard/CounterIndicator.vue";
import SalesTable from "@/components/Dashboard/SalesTable.vue";
import ShipmentSummaryReport from "@/components/Dashboard/ShipmentSummaryReport.vue";
import BucketsTable from "@/components/Dashboard/BucketsTable.vue";
import PrintQueueTable from "@/components/Dashboard/PrintQueueTable.vue";
import { DashboardStatus } from "@/models/DashboardStatus";
import { Component, Vue } from 'vue-property-decorator';
import AnimatedNumber from "@/components/AnimatedNumber.vue";
import moment from "moment";
import { BvTableProviderCallback } from "bootstrap-vue";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import axios from "axios";
import DispenseStatusComponent from "@/pages/Dashboard/Dashboard/DispenseStatusComponent.vue";
import draggable from 'vuedraggable';

@Component({
    components: {
        DispenseStatusComponent,
        CounterIndicator, SalesTable, BucketsTable, AnimatedNumber, ShipmentSummaryReport, PrintQueueTable, draggable
    }
})
export default class Dashboard extends Vue {

    private enabledParts: Array<string> = [];
    private availableCards: Array<[string, string]> = [
        ["dispensingStatusCard", "Card: Dispensing Status"],
        ["processingStatusCard", "Card: Processing Status"],
        ["workQueueCard", "Card: Work Queue Status"],
        ["printQueueCard", "Card: Print Queue Status"],
        ["salesStatusCard", "Card: Sales Status"],
    ];
    private availableIndicators: Array<[string, string]> = [
        ["insuranceQueueCount", "Indicator: Insurance Queue"],
        ["allergyQueueCount", "Indicator: Allergy Queue"],
        ["onlineOrdersQueueCount", "Indicator: Online Orders Queue"],
        ["rxTransferQueueCount", "Indicator: Rx Transfer Queue"],
        ["openCasesQueueCount", "Indicator: Open Cases Queue"],
        ["consultationsQueueCount", "Indicator: Consultations Queue"],
        ["pendingFollowUpQueueCount", "Indicator: Doctor Follow Up Queue"],
        ["autoRefillQueueCount", "Indicator: Autorefill Queue"],
        ["consignmentQueueCount", "Indicator: Consignment Queue"],
        ["pendingBillingQueueCount", "Indicator: Pending Billing Queue"],
        ["voicemails", "Indicator: Voicemail Queue"],
        ["partialPaymentQueueCount", "Indicator: Partial Payment Queue"],
        ["reversalQueueCount", "Indicator: Reversal Queue"],
        ["returnedOrders", "Indicator: Returned Packages Queue"],
        ["patientAssistanceProgramQueueCount", "Indicator: Patient Assistance Program Queue"],
        ["priorAuthorizationQueueCount", "Indicator: Prior Authorization Queue"],
        ["patientMergeRequestQueueCount", "Indicator: Patient Merge Request Queue"],
        ["expiredOnOrderQueueCount", "Indicator: Expired on Order Queue"]
    ];
    created() {
        this.loadData();
        this.retrieveEnabledParts();
    }

    loadData() {
        axios.get(`/api/Dashboard/unearned-revenue`)
            .then((resp) => {
                console.log("loadData() payload:");
                console.log(resp.data)
            }).catch((err) => {
                this.$notification(NotificationOptions.error(err));
            }).finally(() => {
                // this.isLoading = false;
            });
    }

    get status(): DashboardStatus {
        if (this.$root.$data.dashboardData) {
            return this.$root.$data.dashboardData as DashboardStatus;
        }
        return new DashboardStatus();
    }

    closeEnabledPartsModal() {
        this.retrieveEnabledParts();
        this.$bvModal.hide('dashboardPreference');
    }

    saveEnabledParts() {
        localStorage.setItem("enabledDashboardParts", JSON.stringify(this.enabledParts.sort((p: any, p2: any) => this.availableCards.map((a: any) => a[0]).indexOf(p) - this.availableCards.map((a: any) => a[0]).indexOf(p2))));
        this.$bvModal.hide('dashboardPreference');
    }

    retrieveEnabledParts() {
        let savedParts = localStorage.getItem("enabledDashboardParts");

        if (savedParts) {
            this.enabledParts = JSON.parse(savedParts);
        }
        else {
            this.enabledParts = this.availableCards.map((part) => part[0]);
        }
    }

    isPartEnabled(partName: string) {
        return this.enabledParts.indexOf(partName) >= 0 || this.enabledParts.length == 0;
    }

    getWarningLimit(counterName: string): number | null {
        const val = this.status?.getConfigValue(counterName) || '';
        if (isNaN(parseFloat(val))) return null;

        return parseFloat(val);
    }

    getOldestPickUpLimit(_counterName: string): number | null {
        const oldestPickups = this.status?.dispenseStatus.map(ds => moment(ds.oldestPickUp).toDate());
        if (!oldestPickups) return null;
        let isBeyondLimit = false;
        const limitDate = moment().add(-5, 'days').toDate();
        oldestPickups.forEach(element => {
            if (element < limitDate) {
                isBeyondLimit = true;
                return;
            }
        });
        if (isBeyondLimit) return Number.MIN_VALUE;
        return null;
    }

    emitEvent(eventName: string) {
        this.$root.$emit(eventName);
    }

};
</script>

<style>
.processingstatus>.col {
    flex: 0 0 12.5%;
}

.bold-centered {
    text-align: center;
    font-weight: bold;
    color: black;
}

.tooltip-inner {
    max-width: 100% !important;
}
</style>
